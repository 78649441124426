<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0 text-capitalize">
          {{ isAdd ? `${$t("actions.add")} ${$t("intelexvision_alarm_type.alarm_type")}` :`${$t("actions.edit")} ${$t("intelexvision_alarm_type.alarm_type")}` }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('intelexvision_alarm_type.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="itemData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
            #default="validationContext"
            name="description"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('intelexvision_alarm_type.description')"
              label-for="description">
              <b-form-input
                id="description"
                v-model="itemData.description"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- priority -->
          <validation-provider
            #default="validationContext"
            name="priority"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('intelexvision_alarm_type.priority')"
              label-for="priority"
              :state="getValidationState(validationContext)"
            >

              <v-select
                v-model="itemData.priority"
                :options="optionsPriority"
                :clearable="false"
                input-id="priority"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- alarm_type_intelexvision -->
          <validation-provider
            #default="validationContext"
            name="alarm_type_intelexvision"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('intelexvision_alarm_type.alarm_type_intelexvision')"
              label-for="alarm_type_intelexvision"
              :state="getValidationState(validationContext)"
            >

              <v-select
                v-model="itemData.alarm_type_intelexvision"
                :options="optionsTypeAlarm"
                :clearable="false"
                input-id="alarm_type_intelexvision"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Alarm Type -->
          <validation-provider
            #default="validationContext"
            name="alarm"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('intelexvision_alarm_type.alarm_type')"
              label-for="alarm"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_alarm_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="alarmTypeOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="alarm_type"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Tenant -->
          <validation-provider
            #default="validationContext"
            name="tenant"
            rules="required"
          >
            <b-form-group
              :label="$tc('tenants.title', 2)"
              label-for="tenant"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="tenantArray"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tenantOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="tenant"
                multiple
                @input="changeTenant"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Alarm level -->
          <validation-provider
            #default="validationContext"
            name="alarm_level"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$tc('alarm_level.title', 1)"
              label-for="alarm_level"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_alarm_level"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="alarmLevelOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="alarm_level"
                :disabled="tenantArray.length < 1"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Rule -->
          <validation-provider name="rule" rules="required">
            <b-form-checkbox
              id="rule"
              v-model="itemData.rule"
              name="rule"
              value="1"
              unchecked-value="0"
              class="my-1 text-capitalize"
              switch
            >
               {{ $t("intelexvision_alarm_type.rule") }}
            </b-form-checkbox>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ $t("actions.save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
             {{$t('actions.cancel')}}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { required } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    alarmTypeOptions: {
      type: Array,
      required: true,
    },
    tenantOptions: {
      type: Array,
      required: true,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      optionsPriority: [
        "Unknown",
        "Very Low",
        "Low",
        "Moderate",
        "High",
        "Critical"
      ],
      optionsTypeAlarm: [
        "Density",
        "Trex",
        "LeftObject",
        "Tripwire",
        "UnusualBehaviour",
        "Entering",
        "Exiting"
      ]
    };
  },
  setup(props, { emit }) {
    const tenantArray = ref([]);
    const blankData = {
      name: "",
      description: "",
      id_alarm_type: null,
      id_alarm_level: null,
      rule: 0,
      tenants: null,
    };

    const alarmLevelOptions = ref();

    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    const onSubmit = () => {
      let dispatch;
      itemData.value.tenants = tenantArray;
      if (props.isAdd) {
        itemData.value.tenants = tenantArray;
        dispatch = store.dispatch("intelexvision_alarm_type/add", itemData.value);
      } else {
        const req = {
          id: itemData.value.id,
          params: itemData.value,
        };

        dispatch = store.dispatch("intelexvision_alarm_type/edit", req);
      }

      dispatch
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData);

    const loadData = () => {
      tenantArray.value = [];
      if (!props.isAdd) {
        itemData.value = props.itemEdit;
        tenantArray.value = props.itemEdit.tenants.map(tenant => (tenant.id));
        changeTenant();
      }
    };

    const changeTenant = () => {
      if (tenantArray.value.length > 0) {
        store
          .dispatch("alarm_level/getAll", {
            id_tenant: tenantArray.value,
          })
          .then(({ data }) => {
            alarmLevelOptions.value = data.map((alarm_level) => ({
              value: alarm_level.id,
              label: `${alarm_level.level} - ${alarm_level.description}`,
            }));
          })
      } else {
        alarmLevelOptions.value = [];
        itemData.value.id_alarm_level = null;
      }
    };

    return {
      itemData,
      onSubmit,
      tenantArray,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
      alarmLevelOptions,
      changeTenant,
    };
  },
};
</script>